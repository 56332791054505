import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_billing_rates_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { billingContractLineId?: number, typeId?: number, accessorialTemplateLineId?: number }): 
  Promise<{ result: { Id?: number, BillingAccessorialChargeTemplateLineId?: number, BillingCodeId?: number, BillingContractLineId?: number, LookupCode?: string, MinimumCharge?: number, PerAmount?: number, RangeEnd?: number, RangeStart?: number, Rate?: number, TypeId?: number, BillingRateTypes?: { Name?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { billingContractLineId?: number, typeId?: number, accessorialTemplateLineId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, BillingAccessorialChargeTemplateLineId?: number, BillingCodeId?: number, BillingContractLineId?: number, LookupCode?: string, MinimumCharge?: number, PerAmount?: number, RangeEnd?: number, RangeStart?: number, Rate?: number, TypeId?: number, BillingRateTypes?: { Name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { billingContractLineId?: number, typeId?: number, accessorialTemplateLineId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, BillingAccessorialChargeTemplateLineId?: number, BillingCodeId?: number, BillingContractLineId?: number, LookupCode?: string, MinimumCharge?: number, PerAmount?: number, RangeEnd?: number, RangeStart?: number, Rate?: number, TypeId?: number, BillingRateTypes?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      billingContractLineId?: number, typeId?: number, accessorialTemplateLineId?: number    }) {
      return false;
    }
}
