import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_billing_records_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { ownerId?: number, projectId?: number, materialIds?: number[], warehouseIds?: number[], orderIds?: number[], fullTextSearch?: string, includeCancelled: boolean, includeInvoiced: boolean, createdFrom?: string, createdTo?: string, shipmentIds?: number[], workOrderId?: number, billingCodeIds?: number[] }): 
  Promise<{ result: { Id?: number, Amount?: number, BillingCodeId?: number, Cancelled?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, Notes?: string, ProjectId?: number, UomId?: number, BillingRecord?: { ActualAmount?: number, ActualUOM?: number, ApplicableMinimumCharge?: number, ApplicableRate?: number, InvoiceLineId?: number, Notes?: string, Total?: number, InvoiceLine?: { Id?: number, InvoiceId?: number, LineNumber?: number, Invoice?: { Id?: number, Status?: { Name?: string } } }, ActualMeasurementUnit?: { Short_name?: string } }, CancelledReasonCode?: { Id?: number, LookupCode?: string }, BillingCode?: { Name?: string }, Uom?: { Name?: string, Short_name?: string }, LicensePlate?: { LookupCode?: string }, Location?: { Name?: string }, Lot?: { LookupCode?: string }, Material?: { LookupCode?: string }, Order?: { LookupCode?: string }, Warehouse?: { Name?: string }, WorkOrder?: { Id?: number }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, OperationCode?: { Name?: string }, Packaging?: { ShortName?: string }, BillingContractLine?: { Id?: number, BillingAggregationStrategy?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.includeCancelled)) {
      missingRequiredInParams.push('\'includeCancelled\'');
    }
    if (isNil(inParams.includeInvoiced)) {
      missingRequiredInParams.push('\'includeInvoiced\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { ownerId?: number, projectId?: number, materialIds?: number[], warehouseIds?: number[], orderIds?: number[], fullTextSearch?: string, includeCancelled: boolean, includeInvoiced: boolean, createdFrom?: string, createdTo?: string, shipmentIds?: number[], workOrderId?: number, billingCodeIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, Amount?: number, BillingCodeId?: number, Cancelled?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, Notes?: string, ProjectId?: number, UomId?: number, BillingRecord?: { ActualAmount?: number, ActualUOM?: number, ApplicableMinimumCharge?: number, ApplicableRate?: number, InvoiceLineId?: number, Notes?: string, Total?: number, InvoiceLine?: { Id?: number, InvoiceId?: number, LineNumber?: number, Invoice?: { Id?: number, Status?: { Name?: string } } }, ActualMeasurementUnit?: { Short_name?: string } }, CancelledReasonCode?: { Id?: number, LookupCode?: string }, BillingCode?: { Name?: string }, Uom?: { Name?: string, Short_name?: string }, LicensePlate?: { LookupCode?: string }, Location?: { Name?: string }, Lot?: { LookupCode?: string }, Material?: { LookupCode?: string }, Order?: { LookupCode?: string }, Warehouse?: { Name?: string }, WorkOrder?: { Id?: number }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, OperationCode?: { Name?: string }, Packaging?: { ShortName?: string }, BillingContractLine?: { Id?: number, BillingAggregationStrategy?: { Name?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.includeCancelled)) {
      missingRequiredInParams.push('\'includeCancelled\'');
    }
    if (isNil(inParams.includeInvoiced)) {
      missingRequiredInParams.push('\'includeInvoiced\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerId?: number, projectId?: number, materialIds?: number[], warehouseIds?: number[], orderIds?: number[], fullTextSearch?: string, includeCancelled: boolean, includeInvoiced: boolean, createdFrom?: string, createdTo?: string, shipmentIds?: number[], workOrderId?: number, billingCodeIds?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, Amount?: number, BillingCodeId?: number, Cancelled?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, Notes?: string, ProjectId?: number, UomId?: number, BillingRecord?: { ActualAmount?: number, ActualUOM?: number, ApplicableMinimumCharge?: number, ApplicableRate?: number, InvoiceLineId?: number, Notes?: string, Total?: number, InvoiceLine?: { Id?: number, InvoiceId?: number, LineNumber?: number, Invoice?: { Id?: number, Status?: { Name?: string } } }, ActualMeasurementUnit?: { Short_name?: string } }, CancelledReasonCode?: { Id?: number, LookupCode?: string }, BillingCode?: { Name?: string }, Uom?: { Name?: string, Short_name?: string }, LicensePlate?: { LookupCode?: string }, Location?: { Name?: string }, Lot?: { LookupCode?: string }, Material?: { LookupCode?: string }, Order?: { LookupCode?: string }, Warehouse?: { Name?: string }, WorkOrder?: { Id?: number }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, OperationCode?: { Name?: string }, Packaging?: { ShortName?: string }, BillingContractLine?: { Id?: number, BillingAggregationStrategy?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.includeCancelled)) {
      missingRequiredInParams.push('\'includeCancelled\'');
    }
    if (isNil(inParams.includeInvoiced)) {
      missingRequiredInParams.push('\'includeInvoiced\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      ownerId?: number, projectId?: number, materialIds?: number[], warehouseIds?: number[], orderIds?: number[], fullTextSearch?: string, includeCancelled: boolean, includeInvoiced: boolean, createdFrom?: string, createdTo?: string, shipmentIds?: number[], workOrderId?: number, billingCodeIds?: number[]    }) {
        if(isNil(inParams.includeCancelled)) {
          return true; 
        }
        if(isNil(inParams.includeInvoiced)) {
          return true; 
        }
      return false;
    }
}
