import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_billing_records_by_invoiceline_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { invoiceLineId: number }): 
  Promise<{ result: { BillingTaskId?: number, ActualAmount?: number, ActualUOM?: number, ApplicableMinimumCharge?: number, ApplicableRate?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, InvoiceLineId?: number, Notes?: string, Total?: number, BillingTask?: { Id?: number, Amount?: number, BillingCodeId?: number, Cancelled?: boolean, Notes?: string, ProjectId?: number, UomId?: number, CancelledReasonCode?: { Id?: number, LookupCode?: string }, BillingCode?: { Name?: string }, Uom?: { Name?: string, Short_name?: string }, LicensePlate?: { LookupCode?: string }, Location?: { Name?: string }, Lot?: { LookupCode?: string }, Material?: { LookupCode?: string }, Order?: { LookupCode?: string }, Warehouse?: { Name?: string }, WorkOrder?: { Id?: number }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, OperationCode?: { Name?: string }, Packaging?: { ShortName?: string } }, ActualMeasurementUnit?: { Short_name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceLineId)) {
      missingRequiredInParams.push('\'invoiceLineId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { invoiceLineId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { BillingTaskId?: number, ActualAmount?: number, ActualUOM?: number, ApplicableMinimumCharge?: number, ApplicableRate?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, InvoiceLineId?: number, Notes?: string, Total?: number, BillingTask?: { Id?: number, Amount?: number, BillingCodeId?: number, Cancelled?: boolean, Notes?: string, ProjectId?: number, UomId?: number, CancelledReasonCode?: { Id?: number, LookupCode?: string }, BillingCode?: { Name?: string }, Uom?: { Name?: string, Short_name?: string }, LicensePlate?: { LookupCode?: string }, Location?: { Name?: string }, Lot?: { LookupCode?: string }, Material?: { LookupCode?: string }, Order?: { LookupCode?: string }, Warehouse?: { Name?: string }, WorkOrder?: { Id?: number }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, OperationCode?: { Name?: string }, Packaging?: { ShortName?: string } }, ActualMeasurementUnit?: { Short_name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceLineId)) {
      missingRequiredInParams.push('\'invoiceLineId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { invoiceLineId: number, $keys: number[] }): 
  Promise<{ result: { BillingTaskId?: number, ActualAmount?: number, ActualUOM?: number, ApplicableMinimumCharge?: number, ApplicableRate?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, InvoiceLineId?: number, Notes?: string, Total?: number, BillingTask?: { Id?: number, Amount?: number, BillingCodeId?: number, Cancelled?: boolean, Notes?: string, ProjectId?: number, UomId?: number, CancelledReasonCode?: { Id?: number, LookupCode?: string }, BillingCode?: { Name?: string }, Uom?: { Name?: string, Short_name?: string }, LicensePlate?: { LookupCode?: string }, Location?: { Name?: string }, Lot?: { LookupCode?: string }, Material?: { LookupCode?: string }, Order?: { LookupCode?: string }, Warehouse?: { Name?: string }, WorkOrder?: { Id?: number }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, OperationCode?: { Name?: string }, Packaging?: { ShortName?: string } }, ActualMeasurementUnit?: { Short_name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceLineId)) {
      missingRequiredInParams.push('\'invoiceLineId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      invoiceLineId: number    }) {
        if(isNil(inParams.invoiceLineId)) {
          return true; 
        }
      return false;
    }
}
